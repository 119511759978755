



























































import { Component, Vue } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsSubscriptionPromoCard from '@/modules/shop/components/VsSubscriptionPromoCard/Index.vue'
import { getUserPlan } from '@/utils/shop'
import { UserModule } from '@/store/modules/user'
import { createCart, getPricesAddable } from '@/api/shop'
import moment from 'moment'
import { get } from 'lodash'

@Component({
    name: 'HappyHour',
    components: {
        VsContainer,
        VsFullTopBarLayout,
        VsSectionHeader,
        VsSubscriptionPromoCard,
    },
})

export default class extends Vue {
    productsAddable = []

    get user () {
        return UserModule.user
    }

    get shopUser () {
        return UserModule.shopUser
    }

    get planSlug () {
        return 'happyhourestate2024'
    }

    get translatePath () {
        return 'shop.planShopModal'
    }

    get userPlanPriceId () {
        const userPlan = getUserPlan(this.shopUser)
        if (!userPlan) return
        return userPlan.priceId || ''
    }

    get userExpiredPolicy () {
        return UserModule.user.configuration.rules.expiredPolicy
    }

    get showHappy () {
        const userCreatedDate = moment(this.user.createdAt)
        const startDate = moment('2024-06-30', 'YYYY-MM-DD')
        const endDate = moment('2024-09-30', 'YYYY-MM-DD')
        const today = moment()

        return (
            userCreatedDate.isSameOrAfter(startDate) &&
            today.isBetween(startDate, endDate, 'day', '[]') &&
            !get(this.shopUser, 'subscription', false) &&
            this.hasShop &&
            this.userExpiredPolicy === 'email_recharge_at'
        ) || (
            today.isBetween(startDate, endDate, 'day', '[]') &&
            this.userExpiredPolicy === 'last_login'
        )
    }

    get hasShop () {
        return get(this.user, 'configuration.rules.shop', false)
    }

    public async beforeMount () {
        if (!this.showHappy) {
            this.$router.replace({
                name: 'dashboard',
            })
        }
        this.getPricesAddable()
    }

    private async getPricesAddable () {
        try {
            const resp = await getPricesAddable()
            this.productsAddable = resp.data
        } catch (e) {
            console.log(e)
            this.productsAddable = []
        }
    }

    private async createCart (event: any, annualRate: string) {
        try {
            const priceId = event.priceId
            const resp = await createCart({
                items: [{
                    priceId,
                    quantity: 1,
                }],
            })
            this.$emit('cart-created', resp.data)
            this.trackCartCreatedMixpanelEvent(resp.data, this.userExpiredPolicy === 'last_login' ? 'HappyHourEstate24Freemium' : 'HappyHourEstate24Essential', annualRate)

            this.$router.push({
                name: 'cart',
            })
        } catch (e) {
            console.log(e)
        }
    }

    private findSelectedPrice (slug: string, annualRate: string) {
        return this.productsAddable.find((el: any) => {
            const rateType = annualRate
            return el.product.metadata?.ruleGroup === slug && el.rate && el.rate.type === rateType
        })
    }

    private trackCartCreatedMixpanelEvent (cart: any, sourceName: string, annualRate: string) {
        if (UserModule.Mixpanel) {
            UserModule.Mixpanel.track(
                'CartCreated',
                {
                    distinct_id: this.user._id,
                    CartId: cart._id,
                    Trial: false,
                    HasSubscription: false,
                    Interval: annualRate,
                    Source: sourceName,
                },
            )
        }
    }
}
